import React, { ReactNode } from 'react'
import { Divider } from '@nextui-org/react'

export default function BlockTitle({
  title,
  description = '',
  children = undefined,
  size = 'md',
  contentStart = undefined,
  contentBottom = undefined,
  variant = 'black',
  isStandAlone = false,
  className = '',
  strike = false,
  fontWeight = 'bold',
}: {
  title: string
  description?: string
  children?: any | undefined
  size?: 'xs' | 'sm' | 'md' | 'lg'
  isStandAlone?: boolean
  variant?: 'grey' | 'black'
  contentStart?: ReactNode | undefined
  contentBottom?: ReactNode | undefined
  className?: string
  fontWeight?: 'extrabold' | 'bold' | 'thin' | 'light'
  strike?: boolean
}) {
  const sizeClass = {
    xs: 'text-lg',
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-3xl',
  }
  return (
    <div className={'w-full flex flex-col gap-3 '+className}>
      <div
        className={
          'w-full flex flex-row flex-wrap sm:flex-nowrap gap-2 sm:gap-3 ' + ((isStandAlone===true)?' px-3 md:px-0 ':'')
        }
      >
        <div className={'flex items-start align-start'}>
          {contentStart && <div className={'mr-4'}>{contentStart}</div>}
          <div>
            <h2
              className={
                'font-bold text-black ' +
                sizeClass[size] +
                ' ' +
                (strike
                  ? 'line-through font-base'
                  : 'tracking-tight ' + ('font-' + fontWeight))
              }
            >
              {title}
            </h2>
            {description && (
              <div
                className={
                  'font-light '+(size==='xs'?'text-xs mt-0':('text-sm '))
                }
              >
                {description}
              </div>
            )}
          </div>
        </div>
        {children && (
          <div
            className={
              'grow justify-end items-end flex flex-row flex-wrap md:flex-nowrap gap-4 text-sm'
            }
          >
            {children}
          </div>
        )}
      </div>
      {contentBottom && 
      <>
        <Divider />
        {contentBottom}
      </>
      }
    </div>
  )
}
